<template>
  <main class="flex flex-col lg:pb-0 lg:pt-5">
    <div class="relative mb-4.5 lg:mb-0">
      <UiGradientBackground class="absolute left-0 top-3 h-[calc(100%-24px)] lg:hidden" />
      <div class="relative mb-1 h-fit lg:mb-18">
        <UiGradientBackground class="absolute left-0 top-3 hidden h-[calc(100%-24px)] lg:block" />
        <UiContainer tag="section" class="!p-0 lg:grid lg:grid-cols-5 lg:gap-6 lg:!px-7">
          <EventPoster :value="event" :loading="!initialized" class="lg:col-span-3" />
          <EventBaseInfo
            :value="event"
            :loading="!initialized"
            class="relative z-20 -mt-5 w-full lg:col-span-2 lg:mt-0"
          />
        </UiContainer>
      </div>

      <EventTimetablePreview
        v-if="showTimeTablePreview"
        :id="GlobalIds['eventTimetablePreview']"
        :value="event"
        :loading="!initialized"
        class="lg:mb-18"
        @open-timetable="openTimetable"
      />
    </div>

    <div class="relative mb-4.5 lg:mb-18">
      <UiGradientBackground class="absolute left-0 top-0 hidden lg:block" />
      <UiContainer class="relative flex flex-col gap-y-4 py-4.5 lg:grid lg:grid-cols-5 lg:gap-x-6">
        <EventDescription
          :value="event"
          :loading="!initialized"
          class="relative order-1 col-span-4"
        />
        <EventTags :value="event" class="sticky right-0 top-0 order-3 col-span-1 lg:order-2" />
        <EventGallery
          v-if="showGallery"
          :value="event"
          :loading="!initialized"
          class="relative order-2 col-span-5 -mr-4 lg:order-3 lg:-mb-3 lg:mr-0 lg:pb-0"
        />
      </UiContainer>
    </div>

    <EventVenues
      v-if="showVenues"
      :value="event"
      :loading="!initialized"
      class="lg:mb-18"
      :class="{ 'mb-9': compilations }"
    />

    <template v-if="compilations">
      <CompilationWidget
        v-for="(compilation, index) in compilations"
        :key="compilation.slug"
        type="list"
        :size="index > 0 && !(index % 2) ? 'small' : 'big'"
        :value="compilation"
        :class="index === compilations.length - 1 ? 'lg:mb-18 mb-9' : 'lg:mb-9 mb-5'"
      />
    </template>

    <EventOrganizer
      v-if="event?.organizer"
      :value="event.organizer"
      class="mt-auto pb-4.5 lg:!pb-4.5"
      :class="{ '!pb-20': isHelperButtonVisible }"
    />
    <UiContainer v-if="event && isLgAndDown" class="!fixed bottom-4 z-30 w-full lg:hidden">
      <TransitionFade>
        <UseSeanceTimetableHelper
          v-if="event?.seances?.length"
          v-model:visible="isHelperButtonVisible"
          :event-title="event.title"
          :image="event.image"
          :value="event.seances"
          class="w-full"
        />
      </TransitionFade>
    </UiContainer>

    <UiDialog
      v-if="showTimeTablePreview"
      :model-value="isTimetableVisible"
      position="bottom"
      :fit="isLgAndUp"
      :swipe-close="isLgAndDown"
      @update:model-value="closeTimetable"
    >
      <div
        class="relative h-[calc(100dvh_-_16px)] w-full rounded-t-3xl border border-card-primary bg-primary lg:h-[calc(100dvh_-_64px)] lg:w-[58vw] lg:min-w-[739px] lg:!max-w-[58vw]"
      >
        <UiButton
          icon
          class="!absolute right-4 top-3 z-20 size-10 bg-primary lg:-top-7 lg:right-5 lg:size-7 lg:!bg-transparent"
          @click="closeTimetable"
        >
          <UiIcon name="x-mark" class="size-7 text-text-main lg:text-light" />
        </UiButton>
        <EventTimetable
          :value="event"
          :loading="!initialized"
          class="scrollbar-hidden h-full overflow-y-scroll !rounded-t-3xl"
        />
      </div>
    </UiDialog>
  </main>
</template>

<script setup lang="ts">
import { TransitionFade } from '@morev/vue-transitions'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, onBeforeMount, ref } from 'vue'
import { type LocationQuery, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { EventBaseInfo, EventDescription, EventPoster, EventTags } from '@/3_widgets/Event'
import { UseSeanceTimetableHelper } from '@/4_features/Seance'
import { useCompilation } from '@/5_entities/Compilation'
import { useEvent } from '@/5_entities/Event'
import { useQueryParamsOpenState } from '@/6_shared/composables'
import { GlobalIds } from '@/6_shared/config'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiContainer, UiDialog, UiGradientBackground, UiIcon } from '@/6_shared/ui'

const EventTimetablePreview = defineAsyncComponent({
  loader: async () => {
    const { EventTimetablePreview } = await import('@/3_widgets/Event')

    return EventTimetablePreview
  }
})
const EventTimetable = defineAsyncComponent({
  loader: async () => {
    const { EventTimetable } = await import('@/3_widgets/Event')

    return EventTimetable
  }
})
const EventGallery = defineAsyncComponent({
  loader: async () => {
    const { EventGallery } = await import('@/3_widgets/Event')

    return EventGallery
  }
})
const EventVenues = defineAsyncComponent({
  loader: async () => {
    const { EventVenues } = await import('@/3_widgets/Event')

    return EventVenues
  }
})
const CompilationWidget = defineAsyncComponent({
  loader: async () => {
    const { CompilationWidget } = await import('@/3_widgets/Compilation')

    return CompilationWidget
  }
})
const EventOrganizer = defineAsyncComponent({
  loader: async () => {
    const { EventOrganizer } = await import('@/5_entities/Event')

    return EventOrganizer
  }
})

const { getEvent } = useEvent()
const { event, eventInitialized: initialized } = storeToRefs(useEvent())
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
const isHelperButtonVisible = ref(false)
const isTimetableVisible = ref(false)

const route = useRoute()
const { setParamInRouteQuery } = useQueryParamsOpenState('timetable', () => {
  // внутри открываются другие модалки.
  // так что нужно проверять, что закрылись именно они, а не текущая
  if (isTimetableVisible.value && !route.query?.timetable) {
    isTimetableVisible.value = false
  }

  if (!isTimetableVisible.value && route.query?.timetable) {
    isTimetableVisible.value = true
  }
})
const openTimetable = () => {
  isTimetableVisible.value = true

  setParamInRouteQuery('open')
}
const closeTimetable = () => {
  isTimetableVisible.value = false
  setParamInRouteQuery(undefined)
}

const initPage = (id: number, query: LocationQuery) => {
  getEvent(Number(id))
  query.timetable && openTimetable()
  getCompilationsPack(['eventpage', Number(id)].join('/'))
}

onBeforeRouteUpdate(({ params: { id: toId }, query }, { params: { id: fromId } }) => {
  if (fromId !== toId) {
    initPage(Number(toId as string), query)
  }
})

onBeforeMount(() => {
  const { params, query } = useRoute()

  initPage(Number(params.id), query)
})

const showTimeTablePreview = computed(
  () =>
    !initialized.value ||
    (event.value && isLgAndUp.value && event.value.seances.length > 1) ||
    (event.value && isLgAndDown.value && event.value.seances.length)
)
const showGallery = computed(
  () =>
    !initialized.value ||
    (event.value && Array.isArray(event.value.gallery) && event.value.gallery.length)
)
const showVenues = computed(
  () =>
    !initialized.value ||
    (event.value && Array.isArray(event.value.venues) && event.value.venues.length)
)

//compilations
const { getCompilationsPack } = useCompilation()
const { compilations } = storeToRefs(useCompilation())
</script>
