<template>
  <UiObserverHelperButton
    :title="eventTitle"
    :image="image"
    :visible="visible && !!value.length"
    :observable-element-id="GlobalIds['eventTimetablePreview']"
    @click="onButtonClick"
    @update:visible="emit('update:visible', $event)"
  >
    <template #title>
      <template v-if="eventHasOneSeance"> купить билет </template>
      <template v-else-if="eventHasOneDay"> выбрать время </template>
      <template v-else> выбрать дату и время </template>
    </template>
    <template #default-image>
      <UiBackupImage
        :letter="eventTitle[0]"
        :alt="eventTitle"
        class="mr-4 h-[60px] w-[200px] min-w-full max-w-1/4"
      />
    </template>
  </UiObserverHelperButton>
</template>

<script lang="ts" setup>
import { isSameDay } from 'date-fns'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import type { Seance } from '@/5_entities/Seance'
import { getElementByGlobalId, GlobalIds } from '@/6_shared/config'
import { UiBackupImage, UiObserverHelperButton } from '@/6_shared/ui'

type PropType = {
  value: Seance[]
  image: string
  eventTitle: string
  visible: boolean
}
type EmitType = {
  (e: 'update:visible', value: boolean): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const router = useRouter()

const eventHasOneSeance = computed(() => props.value.length === 1)
const eventHasOneDay = computed(() => {
  if (!props.value.length) return false

  const day = props.value[0].beginsAt
  for (const seance of props.value) {
    if (!isSameDay(day, seance.beginsAt)) return false
  }

  return true
})

// логика нажатия на кнопку
const onButtonClick = () => {
  if (eventHasOneSeance.value) {
    router.push({ name: 'SeanceView', params: { id: props.value[0].id } })
  }

  getElementByGlobalId(GlobalIds['eventTimetablePreview'])?.scrollIntoView({
    behavior: 'smooth'
  })
}
</script>
